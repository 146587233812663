import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KEY_CODES } from 'dash-table/utils/unicode';
export default class PageNavigation extends Component {
    constructor(props) {
        super(props);
        this.goToPage = (page_number) => {
            const { paginator } = this.props;
            const page = parseInt(page_number, 10);
            if (isNaN(page)) {
                return;
            }
            paginator.loadPage(page - 1);
        };
    }
    render() {
        const { paginator, page_current } = this.props;
        if (paginator.lastPage !== undefined && paginator.lastPage <= 0) {
            return null;
        }
        const pageChars = Math.max(3, ((paginator.lastPage ?? 0) + 1).toString().length);
        const minWidth = `${pageChars + 1}ch`;
        return (React.createElement("div", { className: 'previous-next-container' },
            React.createElement("button", { className: 'first-page', onClick: paginator.loadFirst, disabled: !paginator.hasPrevious() },
                React.createElement(FontAwesomeIcon, { icon: 'angle-double-left' })),
            React.createElement("button", { className: 'previous-page', onClick: paginator.loadPrevious, disabled: !paginator.hasPrevious() },
                React.createElement(FontAwesomeIcon, { icon: 'angle-left' })),
            React.createElement("div", { className: 'page-number' },
                React.createElement("div", { className: 'current-page-container' },
                    React.createElement("div", { className: 'current-page-shadow', style: { minWidth } }, (page_current + 1).toString()),
                    React.createElement("input", { type: 'text', className: 'current-page', style: { minWidth }, onBlur: event => {
                            this.goToPage(event.target.value);
                            event.target.value = '';
                        }, onKeyDown: event => {
                            if (event.keyCode === KEY_CODES.ENTER) {
                                event.currentTarget.blur();
                            }
                        }, placeholder: (page_current + 1).toString(), defaultValue: '' })),
                paginator.lastPage !== undefined ? ' / ' : '',
                paginator.lastPage !== undefined ? (React.createElement("div", { className: 'last-page', style: { minWidth } }, paginator.lastPage + 1)) : ('')),
            React.createElement("button", { className: 'next-page', onClick: paginator.loadNext, disabled: !paginator.hasNext() },
                React.createElement(FontAwesomeIcon, { icon: 'angle-right' })),
            React.createElement("button", { className: 'last-page', onClick: paginator.loadLast, disabled: paginator.lastPage === undefined || paginator.isLast() },
                React.createElement(FontAwesomeIcon, { icon: 'angle-double-right' }))));
    }
}
