import Logger from 'core/Logger';
class StylesheetFacade {
    constructor(name) {
        this.name = name;
    }
    get rules() {
        const sheet = this.sheet;
        return Array.from(sheet.rules || sheet.cssRules);
    }
    addRule(selector, css) {
        if (this.sheet.addRule) {
            this.sheet.addRule(selector, css);
        }
        else {
            // Firefox
            this.sheet.insertRule(`${selector} { ${css} }`, 0);
        }
    }
    deleteRule(index) {
        this.sheet.deleteRule(index);
    }
    findRule(selector) {
        const rules = this.rules;
        const index = rules.findIndex(r => r.selectorText === selector);
        return index === -1 ? null : { rule: rules[index], index };
    }
    get sheet() {
        return (this.__stylesheet =
            this.__stylesheet ||
                (() => {
                    const style = document.createElement('style');
                    style.type = 'text/css';
                    style.id = this.name;
                    document.getElementsByTagName('head')[0].appendChild(style);
                    return style;
                })()).sheet;
    }
}
export default class Stylesheet {
    constructor(prefix) {
        this.prefix = prefix;
        this.stylesheet = new StylesheetFacade(`${prefix}-dynamic-inline.css`);
    }
    deleteRule(selector) {
        selector = `${this.prefix} ${selector}`;
        const result = this.stylesheet.findRule(selector);
        if (result) {
            this.stylesheet.deleteRule(result.index);
        }
    }
    setRule(selector, css) {
        selector = `${this.prefix} ${selector}`;
        const result = this.stylesheet.findRule(selector);
        if (result) {
            if (result.rule.cssText === css ||
                result.rule.cssText === `${selector} { ${css} }`) {
                return;
            }
            else {
                this.stylesheet.deleteRule(result.index);
            }
        }
        this.stylesheet.addRule(selector, css);
        Logger.trace('stylesheet', selector, css);
    }
}
