import React, { PureComponent } from 'react';
import Dropdown from 'react-select';
import DOM from 'core/browser/DOM';
import dropdownHelper from 'dash-table/components/dropdownHelper';
export default class CellDropdown extends PureComponent {
    constructor() {
        super(...arguments);
        this.handleOpenDropdown = () => {
            const { dropdown } = this.refs;
            dropdownHelper(dropdown.wrapper.querySelector('.Select-menu-outer'));
        };
    }
    render() {
        const { clearable, dropdown, onChange, value, disabled } = this.props;
        return (React.createElement("div", { className: 'dash-dropdown-cell-value-container dash-cell-value-container', onClick: this.handleClick },
            React.createElement("div", { className: 'dropdown-cell-value-shadow cell-value-shadow' }, ((dropdown &&
                dropdown.find(entry => entry.value === value)) || { label: undefined }).label),
            React.createElement(Dropdown, { ref: 'dropdown', clearable: clearable, onChange: (newValue) => {
                    onChange(newValue ? newValue.value : newValue);
                }, scrollMenuIntoView: false, onOpen: this.handleOpenDropdown, options: dropdown, placeholder: '', value: value, disabled: disabled })));
    }
    componentDidUpdate() {
        this.setFocus();
    }
    componentDidMount() {
        this.setFocus();
    }
    handleClick(e) {
        e.stopPropagation();
    }
    setFocus() {
        const { active, applyFocus } = this.props;
        if (!active) {
            return;
        }
        const dropdown = this.refs.dropdown;
        if (applyFocus && dropdown && document.activeElement !== dropdown) {
            // Limitation. If React >= 16 --> Use React.createRef instead to pass parent ref to child
            const tdParent = DOM.getFirstParentOfType(dropdown.wrapper, 'td');
            if (tdParent && tdParent.className.indexOf('phantom-cell') === -1) {
                tdParent.focus();
            }
        }
    }
}
