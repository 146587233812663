import * as R from 'ramda';
import { KnownCssProperties } from '../style/py2jsCssProperties';
import { shallowClone } from 'core/math/matrixZipMap';
export const BORDER_PROPERTIES = [
    'borderBottom',
    'borderLeft',
    'borderRight',
    'borderTop'
];
export const BORDER_PROPERTIES_AND_FRAGMENTS = R.filter(p => p.indexOf('border') === 0, KnownCssProperties);
export class EdgesMatrix {
    constructor(rowsOrMatrix, columns, defaultEdge) {
        this.getEdge = (i, j) => this.edges[i][j];
        this.getEdges = () => this.edges;
        this.getWeight = (i, j) => this.weights[i][j];
        this.isDefault = (i, j) => this.weights[i][j] === -Infinity;
        this.clone = () => new EdgesMatrix(this);
        if (typeof rowsOrMatrix === 'number' &&
            typeof columns !== 'undefined') {
            const rows = rowsOrMatrix;
            this.rows = rows;
            this.columns = columns;
            this.defaultEdge = defaultEdge;
            this.weights = R.map(() => new Array(columns).fill(-Infinity), R.range(0, rows));
            this.edges = R.map(() => new Array(columns).fill(defaultEdge), R.range(0, rows));
        }
        else {
            const source = rowsOrMatrix;
            this.rows = source.rows;
            this.columns = source.columns;
            this.defaultEdge = source.defaultEdge;
            this.weights = shallowClone(source.weights);
            this.edges = shallowClone(source.edges);
        }
    }
    setEdge(i, j, edge, weight, force = false) {
        if (i < 0 || j < 0 || i >= this.rows || j >= this.columns) {
            return;
        }
        if (!force && (R.isNil(edge) || weight <= this.weights[i][j])) {
            return;
        }
        this.weights[i][j] = weight;
        this.edges[i][j] = edge;
    }
}
export class EdgesMatrices {
    constructor(rowsOrMatrix, columns, defaultEdge, horizontalEdges, verticalEdges) {
        this.getEdges = () => ({
            horizontal: this.horizontal.getEdges(),
            vertical: this.vertical.getEdges()
        });
        this.getMatrices = () => ({
            horizontal: this.horizontal,
            vertical: this.vertical
        });
        this.getStyle = (i, j) => ({
            borderBottom: this.horizontal.getEdge(i + 1, j) || null,
            borderTop: this.horizontal.getEdge(i, j) || null,
            borderLeft: this.vertical.getEdge(i, j) || null,
            borderRight: this.vertical.getEdge(i, j + 1) || null
        });
        this.clone = () => new EdgesMatrices(this);
        if (typeof rowsOrMatrix === 'number' &&
            typeof columns !== 'undefined') {
            const rows = rowsOrMatrix;
            this.rows = rows;
            this.columns = columns;
            this.defaultEdge = defaultEdge;
            this.horizontalEdges = R.isNil(horizontalEdges) || horizontalEdges;
            this.verticalEdges = R.isNil(verticalEdges) || verticalEdges;
            this.horizontal = new EdgesMatrix(rows + 1, columns, this.horizontalEdges ? defaultEdge : undefined);
            this.vertical = new EdgesMatrix(rows, columns + 1, this.verticalEdges ? defaultEdge : undefined);
        }
        else {
            const source = rowsOrMatrix;
            this.rows = source.rows;
            this.columns = source.columns;
            this.defaultEdge = source.defaultEdge;
            this.horizontal = source.horizontal.clone();
            this.vertical = source.vertical.clone();
            this.horizontalEdges = source.horizontalEdges;
            this.verticalEdges = source.verticalEdges;
        }
    }
    setEdges(i, j, style) {
        if (this.horizontalEdges) {
            if (style.borderTop) {
                this.horizontal.setEdge(i, j, style.borderTop[0], style.borderTop[1]);
            }
            if (style.borderBottom) {
                this.horizontal.setEdge(i + 1, j, style.borderBottom[0], style.borderBottom[1]);
            }
        }
        if (this.verticalEdges) {
            if (style.borderLeft) {
                this.vertical.setEdge(i, j, style.borderLeft[0], style.borderLeft[1]);
            }
            if (style.borderRight) {
                this.vertical.setEdge(i, j + 1, style.borderRight[0], style.borderRight[1]);
            }
        }
    }
}
